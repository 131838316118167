import * as React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import ReactMarkdown from 'react-markdown'

import { s3VideoPath } from '../constants/app'

// import components
import Layout from '../components/sections/layout'
import Seo from '../components/sections/seo'
import HeaderVideo from '../components/headerVideo'

// import templates
import BasicLayout from '../templates/basic-layout'

import styled from 'styled-components'
import { fontSize } from '../css/mixins/mixins'
const VideoContainer = styled.div`
  background-color: whitesmoke;
  line-height: 0;
  padding: 0;
  margin: 40px 0;
`

const VideoRatio = styled.div`
  position: relative;
  padding-top: 52.8%;
`

const IframeContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const FaqTitle = styled.h3`
  ${fontSize(22)}
  text-align: center;
  margin-top: 80px;
  margin-bottom: 60px;
  position: relative;
  color: #000;
  flex-basis: 100%;

  @media only screen and (min-width: 930px) {
    ${fontSize(26)}
  }

  &:after {
    content: '';
    position: absolute;
    width: 50px;
    height: 2px;
    bottom: -15px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: #000;
  }
`

const ConsentFormTitle = styled.p`
  ${fontSize(22)}
  text-align: center;
  margin-top: 80px;
  margin-bottom: 60px;
  position: relative;
  color: #000;
  flex-basis: 100%;

  @media only screen and (min-width: 930px) {
    ${fontSize(30)}
  }

  &:after {
    content: '';
    position: absolute;
    width: 50px;
    height: 2px;
    bottom: -15px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: #000;
  }
`

const ConsentFormList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 80px;
`

const ConsentFormItem = styled.li`
  background-color: #eeeeee;
  border: 2px #e9e9e9 solid;
  margin-bottom: 20px;
  border-radius: 5px;
  transition:
    0.5s background-color,
    0.5s border;
  overflow: hidden;
  flex-basis: calc(50% - 10px);
  margin: 5px;
  text-align: center;

  &:hover {
    background-color: #3a3a3a;
    border: 2px #917147 solid;
    span {
      text-decoration: underline;
    }
  }

  a {
    padding: 30px 10px;
    display: block;
    text-decoration: none;
    font-weight: 700;
  }
`

const PaymentTitle = styled.p`
  ${fontSize(22)}
  text-align: center;
  margin-top: 80px;
  margin-bottom: 60px;
  position: relative;
  color: #000;
  flex-basis: 100%;

  @media only screen and (min-width: 930px) {
    ${fontSize(30)}
  }

  &:after {
    content: '';
    position: absolute;
    width: 50px;
    height: 2px;
    bottom: -15px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: #000;
  }
`

const PaymentContainer = styled.div`
  background-color: #eeeeee;
  border: 2px #e9e9e9 solid;
  border-radius: 5px;
  padding: 25px;
  position: relative;

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  margin-bottom: 50px;

  @media only screen and (min-width: 600px) {
    flex-direction: row;
  }

  &:after {
    content: '';
    background-color: #cecece;
    width: 70%;
    height: 1px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @media only screen and (min-width: 600px) {
      width: 1px;
      height: 70%;
    }
  }

  div {
    padding: 15px;
    form {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: 0;
      font-weight: 700;
      color: #917147;
      max-width: 250px;

      input[type='text'] {
        background-color: #cecece;
        border: 2px #8e8e8e solid;
        border-radius: 3px;
      }

      tr,
      td {
        border-bottom: 0;
      }
    }
  }
`

const seo_description = `Personal Trainer Hove & Brighton online classes providing people with so much more than just physical health benefits. Social interaction, stress relief, new friendships and an overall improvement to everyone’s mental health with Hove`
const seo_keywords = `Over 60s online classes Hove, 60s plus online class, personal training Hove, personal training Brighton, online personal training, Hove personal trainer, older adults Hove, older adults Brighton, Best personal trainer Hove, Personal Trainer in Brighton, Personal Trainer in Hove, Personal Trainer Near Me, online personal trainer hove, online personal trainer brighton, PT Hove & Brighton`

const PlusClub = () => {
  const Vid = s3VideoPath('over_60.mp4')
  const VidPoster = s3VideoPath('over_60_poster.jpg')

  const data = useStaticQuery(query)
  const { strapiPlusClub } = data
  const { title, description, SEO, faq_description } = strapiPlusClub

  const { page_description = seo_description, page_keywords = seo_keywords, page_title = title } = SEO

  return (
    <Layout>
      <Seo title={page_title} description={page_description} keywords={page_keywords} />
      <HeaderVideo src={Vid} poster={VidPoster} title={title} />
      <VideoContainer>
        <div className="container">
          <VideoRatio>
            <IframeContainer>
              <iframe
                width={'100%'}
                height={'100%'}
                src={'https://www.youtube.com/embed/REKMOP2bD9E?rel=0'}
                title="Ty Paul Over 60's Fitness Class"
                frameBorder="0"
                allow="accelerometer;"
                allowFullScreen
              ></iframe>
            </IframeContainer>
          </VideoRatio>
        </div>
      </VideoContainer>
      <BasicLayout pageName={title} description={description} rightImage={''} images={[]} />
      <div className="container">
        <FaqTitle>Over 60’s+ Club FAQ</FaqTitle>
        <ReactMarkdown children={faq_description} />
      </div>
      <div className="container">
        <ConsentFormTitle>Online Consent Forms</ConsentFormTitle>
        <ConsentFormList>
          <ConsentFormItem>
            <a href={'https://form.jotform.com/221301824259046'} target="_blank" rel="noreferrer">
              Informed Consent Form
            </a>
          </ConsentFormItem>
          <ConsentFormItem>
            <a href={'https://form.jotform.com/221301701982346'} target="_blank" rel="noreferrer">
              Health Screening Form
            </a>
          </ConsentFormItem>
        </ConsentFormList>
      </div>
      <div className="container">
        <PaymentTitle>Payment Methods</PaymentTitle>
        <PaymentContainer>
          <div>
            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="DXB8PUP4RT8VG" />
              <table>
                <tr>
                  <td>
                    <input type="hidden" name="on0" value="1 x 60's+ Club Admission" />1 x 60's+ Club Admission
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: 0 }}>
                    <input type="text" name="os0" maxLength="200" type="hidden" />
                  </td>
                </tr>
              </table>
              <input type="image" src="https://www.paypalobjects.com/en_US/GB/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal – The safer, easier way to pay online!" />
              <img alt="" border="0" src="https://www.paypalobjects.com/en_GB/i/scr/pixel.gif" width="1" height="1" />
            </form>
          </div>

          <div>
            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="CG4JNCWCMG82W" />
              <table>
                <tr>
                  <td>
                    <input type="hidden" name="on0" value="10 x 60's+ Club Admission" />
                    10 x 60's+ Club Admission
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: 0 }}>
                    <input type="text" name="os0" maxLength="200" type="hidden" />
                  </td>
                </tr>
              </table>
              <input type="image" src="https://www.paypalobjects.com/en_US/GB/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal – The safer, easier way to pay online!" />
              <img alt="" border="0" src="https://www.paypalobjects.com/en_GB/i/scr/pixel.gif" width="1" height="1" />
            </form>
          </div>
        </PaymentContainer>
      </div>
      <div className="container">
        <br />
        <br />
        <p>
          <strong>
            If you would like to register your interest in this class please email <Link to={'/about/ty-paul'}>Ty</Link> at <a href="mailto:ty@typaul.co.uk">ty@typaul.co.uk</a> or call{' '}
            <a href="tel:07876655868">07876655868</a>.
          </strong>
        </p>
        <p>
          <strong>Or fill out the enquiry form at the bottom of this page.</strong>
        </p>
        <br />
        <br />
      </div>
    </Layout>
  )
}

const query = graphql`
  {
    strapiPlusClub {
      SEO {
        page_description
        page_keywords
        page_title
      }
      title
      description
      faq_description
    }
  }
`

export default PlusClub
